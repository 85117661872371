<script setup lang="ts">
const props = defineProps<{
  content: string
}>()
</script>

<template>
  <div
    v-html="props.content"
    class="prose prose-sm sm:prose-base lg:prose-lg text-black focus:outline-none"
  ></div>
</template>
